@import "src/styles/modules/mixins", "src/styles/modules/variables";

// footer
.footer {
  padding: 170px 0 39px 0;
  background-color: #305674;
  color: #fff;

  &-info {
    &__contents {
      display: flex;
      justify-content: space-between;
    }
  }

  &-inner {
    max-width: 1024px;
    margin: 0 auto;
    height: 100%;
  }

  &-logo {
    width: 140px;
    height: 40px;
  }

  &-logo__link {
    background: url("../../../assets/common/logo-footer.png") center / cover
      no-repeat;
    display: inline-block;
    width: 115px;
    height: 33px;
    text-indent: -9999px;
  }

  &-address {
    font-style: normal;
    line-height: 1.69;
    letter-spacing: 0.34px;
    font-size: 13px;
    text-align: right;
  }

  &-copyright {
    margin-top: 10px;
    font-size: 15px;
  }

  &-terms__contents {
    display: flex;
    align-items: flex-end;
  }

  &-terms {
    display: inline-block;
    margin-right: 40px;
    &:last-child {
      margin-right: 0;
    }
  }

  &-terms__text {
    line-height: 2.25;
    letter-spacing: 0.32px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
  }
}

// media query
// 1280px
@include mq(desktop) {
  .footer {
    padding: 62px 0 39px 0;
  }

  .footer-inner {
    margin: 0 30px;
    flex-direction: column;
    text-align: center;
  }

  .footer-logo {
    width: 97px;
    height: 28px;
  }

  .footer-info__contents {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-logo__link {
    background: url("../../../assets/common/logo-footer-mobile.png") center /
      cover no-repeat;
    width: 97px;
    height: 28px;
  }

  .footer-address__contents {
    width: 100%;
    margin-left: 0;
  }

  .footer-address {
    margin-top: 11px;
    line-height: 1.54;
    text-align: left;
    font-size: 13px;
  }

  .footer-copyright {
    margin-top: 5px;
  }

  .footer-terms__contents {
    display: none;
  }
}
