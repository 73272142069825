/*
 * File        : product.scss
 * Description : ProductPage 관련 css
*/

.product {
  &-header {
    background: url("../assets/images/product01.jpg") 50% / cover no-repeat;
  }

  &-container {
    .contents-inner {
      text-align: center;
    }
  }

  &-title {
    font-size: 40px;
  }
}

// 1280px
@include mq(desktop) {
  .product-title {
    font-size: 30px;
  }
}
