/*
 * File        : main.scss
 * Description : MainPage 관련 css
*/

.main {
  // section common
  &-section {
    &__title--wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 43px;
    }

    &__title {
      position: relative;
      font-size: 30px;
      font-weight: bold;
      color: #305674;
    }
  }

  // image
  &-image {
    &__area {
      position: relative;
      background: url("../assets/images/main-bg.png") center/ cover no-repeat;
      height: 580px;
      z-index: -2;
    }

    &__title {
      padding-top: 166px;
      font-weight: bold;
      font-size: 60px;
      line-height: 1.25;
      color: #fff;
    }

    &__subtitle {
      margin-top: 23px;
      font-size: 17px;
      font-weight: normal;
      line-height: 1.59;
      color: #fff;

      &--mobile {
        display: none;
      }
    }

    &__text {
      &--mobile {
        display: none;
      }
    }

    &__inner {
      max-width: 1024px;
      margin: 0 auto;
      z-index: 10;
    }

    &__dimmed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(#305674, #305674);
      opacity: 0.37;
      mix-blend-mode: multiply;
      background-blend-mode: multiply;
      z-index: -1;
    }
  }

  &-contents {
    &__wrap {
      position: relative;
      padding: 256px 0 89px 0;
      background: linear-gradient(to top, #effaff, #f7fbfd);
    }

    &__inner {
      max-width: 1024px;
      margin: 0 auto;
    }
  }

  // 더보기 버튼
  &-info {
    &__more {
      display: flex;
      justify-content: flex-end;
      margin-top: 14px;

      a {
        display: flex;
        align-items: center;

        span {
          font-weight: bold;
          color: #305674;
          font-size: 16px;
        }

        &::after {
          content: "";
          width: 25px;
          height: 25px;
          display: inline-block;
          margin-left: 7px;
          background: url("../assets/icons/icon-arrow-next02.png") center /
            cover no-repeat;
        }
      }

      span {
        &::after {
          content: "";
          display: block;
          width: 0;
          height: 2px;
          background-color: #305674;
          transition: 0.1s;
        }

        &:hover {
          &::after {
            width: 100%;
          }
        }
      }
    }
  }

  // company info
  &-company {
    &__info {
      position: absolute;
      top: -56px;
      width: 1024px;

      &--inner {
        padding: 35px 0;
        height: 170px;
        background: #fff;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 30px 30px 0 rgba(48, 86, 116, 0.07);
      }

      &--item {
        width: calc(100% / 3);
        height: 100%;
        padding: 0 80px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        border-right: 1px solid #ededed;

        &:last-child {
          border-right: 0;
        }

        .icon {
          margin-bottom: 15px;
        }

        .icon-ai {
          width: 35px;
          height: 32px;
          background: url(../assets/icons/icon-ai.png) center / cover no-repeat;
        }

        .icon-medical {
          width: 32px;
          height: 32px;
          background: url(../assets/icons/icon-medical.png) center / cover
            no-repeat;
        }

        .icon-health {
          width: 36px;
          height: 32px;
          background: url(../assets/icons/icon-health.png) center / cover
            no-repeat;
        }

        p {
          height: 53px;
          font-size: 20px;
          line-height: 1.35;
          font-weight: bold;
          color: #305674;
        }
      }
    }
  }

  // medical info
  &-medical {
    &__info {
      &--inner {
        text-align: center;

        h2 {
          font-size: 30px;
          font-weight: bold;
          line-height: 1.13;
          letter-spacing: 0.45px;
          color: #305674;
        }

        h3 {
          margin: 10px 0 40px 0;
          font-size: 17px;
          line-height: 1.65;
          color: #305674;
        }
      }
    }

    &__image {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;

      // main slider
      .slick-slider {
        width: 100%;
      }

      .slick-slide[data-index="0"] {
        &:hover {
          .react-player__preview {
            transition: 0.2s;
            background-image: url(../assets/images/medical-info-hover04.png) !important;
          }
        }
      }

      .slick-slide[data-index="1"] {
        &:hover {
          .react-player__preview {
            transition: 0.2s;
            background-image: url(../assets/images/medical-info-hover05.png) !important;
          }
        }
      }

      .slick-slide[data-index="2"] {
        &:hover {
          .react-player__preview {
            transition: 0.2s;
            background-image: url(../assets/images/medical-info-hover01.png) !important;
          }
        }
      }

      .slick-slide[data-index="3"] {
        &:hover {
          .react-player__preview {
            transition: 0.2s;
            background-image: url(../assets/images/medical-info-hover03.png) !important;
          }
        }
      }

      .slick-slide[data-index="4"] {
        &:hover {
          .react-player__preview {
            transition: 0.2s;
            background-image: url(../assets/images/medical-info-hover02.png) !important;
          }
        }
      }

      .slick-prev {
        left: -37px;
        width: 15px;
        height: 28px;

        &::before {
          width: 15px;
          height: 28px;
          background: url(../assets/icons/icon-main-arrow-prev.png) center /
            cover no-repeat;
        }
      }

      .slick-next {
        right: -37px;
        width: 15px;
        height: 28px;

        &::before {
          width: 15px;
          height: 28px;
          background: url(../assets/icons/icon-main-arrow-next.png) center /
            cover no-repeat;
        }
      }

      .slick-prev,
      .slick-next {
        top: 45%;
        transform: translate(0, -45%);
      }

      &--inner {
        height: 188px;

        p {
          font-size: 15px;
          font-weight: 500;
          line-height: 3.13;
          letter-spacing: 0.15px;
        }

        .react-player__preview {
          margin: 0 5px;
        }
      }

      .medical-info__video--item {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  // skin info
  &-skin {
    &__info {
      position: relative;
      height: 756px;
      overflow: hidden;
      background: url("../assets/images/main-partners.png") center / cover
        no-repeat;

      .skin-contents__inner {
        padding: 135px 0 119px 0;
      }

      &--bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 330px;
        background: linear-gradient(#ffffff, rgba(255, 255, 255, 0));
      }

      &--inner {
        position: relative;
        text-align: center;
      }

      &--list {
        display: inline-block;
        width: calc((100% - 8px * 3) / 3);
        height: 100px;
        margin-right: 11px;
        margin-bottom: 11px;
        border-radius: 5px;
        line-height: 100px;

        &:nth-child(2n) {
          background-color: #84a0ab;
        }

        &:nth-child(2n - 1) {
          background-color: #4cb9a9;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      &--item {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        transition: 0.5s;
        border-radius: 5px;
        box-shadow: 0 30px 30px 0 rgba(55, 83, 83, 0.08);

        h2 {
          visibility: hidden;
        }

        h3 {
          font-size: 17px;
          font-weight: 500;
          letter-spacing: 0.28px;
          line-height: 100px;
          color: #fff;
        }

        p {
          font-size: 19px;
          font-weight: 500;
          line-height: 1.74;
          color: #707070;
        }

        &:hover {
          background-color: #305674;
          transition: 0.5s;

          h3 {
            display: none;
          }

          h2 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            visibility: visible;
            color: #fff;
            font-size: 28px;
            font-weight: bold;
            line-height: 1.61;
            letter-spacing: 0.28px;
          }

          span {
            display: block;
            font-size: 16px;
            color: #fff;
            text-decoration: underline;
          }
        }
      }

      &--button {
        display: none;
      }
    }
  }

  // banner
  &-banner {
    &__inner {
      padding: 50px 0;
      &::after {
        content: "";
        display: block;
        clear: both;
      }
    }

    &__list {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;

      a {
        width: 247px;
        height: 72px;
        display: inline-block;
        margin-right: 12px;
        margin-bottom: 12px;

        &:nth-child(4n) {
          margin-right: 0;
        }
      }

      &--inner {
        height: 72px;
        border: 1px solid #ededed;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

// media query
// 1280px
@include mq(desktop) {
  .main-contents__inner {
    max-width: 100%;
  }

  .main-image__area {
    position: relative;
    height: 605px;
    background: url("../assets/images/main-bg-mobile.png") center center / cover
      no-repeat;
  }

  .main-image__inner {
    position: relative;
    height: 100%;
    max-width: 100%;
  }

  .main-image__text {
    padding: 194px 0 0 30px;
  }

  .main-image__title {
    padding-top: 0;
    font-size: 39px;
    line-height: 1.28;
  }

  .main-image__subtitle {
    margin-top: 16px;
    max-width: 291px;
    font-size: 19px;
    line-height: 1.37;
    letter-spacing: -0.19px;
    font-weight: normal;
    color: #fff;
    br {
      display: none;
    }
  }

  .main-image__text--mobile {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
    font-size: 13px;

    &::after {
      content: "";
      display: inline-block;
      width: 13px;
      height: 11px;
      margin-left: 6px;
      background: url(../assets/icons/icon-go.png) center / cover no-repeat;
    }
  }

  .main-section__title--wrap {
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }

  .main-section__title {
    font-size: 27px;
  }

  .main-section__btn {
    margin-top: 5px;
    height: 35px;
    line-height: 35px;
  }

  // company info
  .main-contents__wrap {
    padding: 475px 0 72px 0;
    background: linear-gradient(to top, #effaff, #f0faff, #f7fbfd);
  }

  .main-company__info {
    width: 100%;
    top: -114px;
    padding: 0 47px;
  }

  .main-company__info--inner {
    height: auto;
    padding: 0 30px;
    flex-direction: column;
  }

  .main-company__info--item {
    width: 100%;
    padding: 34px 0;
    display: block;
    border-bottom: 1px solid #ededed;
    border-right: 0;
    align-items: center;
    text-align: center;

    &:last-child {
      border-bottom: 0;
    }

    p {
      height: auto;
      font-size: 22px;
      line-height: 1.41;
      letter-spacing: -0.22px;
    }
  }

  // medical info
  .main-medical__info {
    margin-top: 50px;

    .main-info__more {
      margin-right: 20px;
      padding-top: 50px;

      a {
        &::after {
          width: 30px;
          height: 30px;
          background: url(../assets/icons/icon-arrow-next-small.png) center /
            cover no-repeat;
        }

        span {
          font-size: 18px;
        }
      }
    }
  }

  .main-medical__info--inner {
    h2 {
      font-size: 27px;
      line-height: 1.33;
      letter-spacing: -0.27px;
    }

    h3 {
      font-size: 18px;
      margin: 15px 0 38px 0;
      line-height: 1.44;
      letter-spacing: -0.18px;
    }
  }

  .main-medical__info--text {
    width: 210px;
    margin: 0 auto;
  }

  .main-medical__image {
    flex-direction: column;

    &--inner {
      width: 100%;
      height: 211px;
      margin-right: 0;

      .react-player__preview {
        margin: 0;
      }

      p {
        font-size: 17px;
        line-height: 2.76;
        letter-spacing: -0.17px;
      }
    }

    // main slider
    .slick-slider {
      width: 100%;
      height: 100%;
    }

    .slick-slider .slick-track {
      width: 100% !important;
      transform: translate3d(0, 0, 0) !important;
    }

    .slick-initialized .slick-slide {
      display: none;
    }

    .slick-slide[data-index="0"] {
      display: block;
      width: 100% !important;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  // skin info
  .main-skin__info .skin-contents__inner {
    padding: 70px 0 75px 0;
  }

  .main-skin__info {
    height: auto;
    position: relative;
  }

  .main-skin__info--list {
    width: calc((100% - 11px * 2) / 2);
    height: 46px;
  }

  .main-skin__info--list:nth-child(2n) {
    margin-right: 0;
  }

  .main-skin__info--list:nth-child(6n - 3) {
    margin-right: 11px;
  }

  .main-skin__info--list:nth-child(4n),
  .main-skin__info--list:nth-child(4n - 3) {
    background-color: #84a0ab;
  }

  .main-skin__info--list:nth-child(4n - 2),
  .main-skin__info--list:nth-child(7n) {
    background-color: #4cb9a9;
  }

  .main-skin__info--item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main-skin__info--item:hover {
    background-color: transparent;

    .main-skin__info--button {
      display: none;
    }
    h3 {
      display: block;
    }
  }

  .main-skin__info--item h3 {
    font-size: 17px;
    font-weight: normal;
    line-height: 1.71;
    letter-spacing: 0.26px;
  }

  .main-skin__info--item p {
    font-size: 12px;
  }

  .main-skin__info--button {
    display: none;
  }

  .main-skin__info--button {
    width: 30px;
    height: 30px;
    right: 30px;
    bottom: 20px;
    background: url("../assets/icons/icon-arrow-next.png") center / cover
      no-repeat;
  }

  .main-info__more.skin {
    display: none;
  }

  .main-info__more span:hover {
    &::after {
      width: 0;
    }
  }

  // banner
  .main-banner__inner {
    text-align: center;
  }

  .main-banner__list a {
    width: 228px;
  }

  .main-banner__list a:nth-child(3n) {
    margin-right: 0;
  }

  .main-banner__list a:nth-child(4n) {
    margin-right: 12px;
  }
}

@media screen and (min-width: 1024px) and (max-height: 1366px) {
  .main-banner__list a {
    flex-grow: 1;
    flex-shrink: 0;
  }

  .main-banner__list a:nth-child(3n) {
    margin-right: 12px;
  }

  .main-banner__list a:nth-child(4n) {
    margin-right: 0;
  }
}

// slick
.center .slick-center .main-banner__bg {
  transform: scale(1.1);
  opacity: 1;
}
.center .main-banner__bg {
  opacity: 0.8;
  transition: all 0.3s ease;
}

.slick-dots li.slick-active button:before {
  color: #4cb9a9;
}

.slick-prev,
.slick-next {
  left: 10px;
  z-index: 10;
}
.slick-next {
  left: auto;
  right: 10px;
}

// 767px
@include mq(mobile) {
  // medical
  .main-medical__image {
    height: 211px;
  }

  // banner
  .main-banner__list {
    flex-direction: column;
    align-items: center;
  }

  .main-banner__list a {
    margin-right: 0;
  }

  .main-banner__item {
    margin-right: 0;
  }

  .main-banner__list a:nth-child(4n) {
    margin-right: 0;
  }
}

@include mq(mini) {
  // image
  .main-image__title {
    font-size: 25px;
  }

  .main-image__subtitle {
    font-size: 15px;
  }

  // service
  .main-service__info--mobile {
    padding-left: 20px;
  }

  // company
  .main-company__info--item {
    padding: 20px;
  }

  .main-company__info--item p {
    font-size: 15px;
  }

  // medical
  .main-medical__image {
    height: auto;
  }
}
