/*
 * File        : skinInfo.scss
 * Description : SkinInfoPage 관련 css
*/

.skin {
  &-header {
    background: url("../assets/images/skin01.jpg") 50% / cover no-repeat;
  }

  &-container {
    margin-top: 50px;
  }

  &-contents {
    max-width: 580px;
    padding: 40px 0 0 0;
    margin: 0 auto;

    &__text {
      font-size: 17px;
      font-weight: 600;
      color: #4cb9a9;
      letter-spacing: 0.34px;
      font-family: "Inter";
    }

    &__title {
      font-size: 31px;
      font-weight: bold;
      letter-spacing: 0.6px;
      color: #305674;

      &--wrap {
        margin: 30px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &--img {
        width: 169px;
        height: 153px;
        border: 1px solid #707070;
      }
    }

    // info
    &__info {
      h4 {
        font-size: 16px;
        margin-bottom: 70px;
        font-weight: 500;
        line-height: 1.85;
        color: #305674;
      }
    }

    // image
    &__image {
      &--info {
        max-width: 1024px;
        margin: 0 auto;
        padding: 70px 0 250px 0;
        text-align: center;

        &::after {
          content: "";
          display: block;
          clear: both;
        }

        .title {
          font-size: 27px;
          font-weight: bold;
          line-height: 1.22;
          color: #000;
        }

        .info {
          margin-bottom: 10px;
          display: block;
          font-size: 20px;
          font-weight: 500;
          line-height: 1.2;
          color: #000;
        }

        .sub-info {
          font-size: 15px;
          color: #555;
        }

        .image-contents {
          width: 180px;
          display: flex;
          flex-direction: column;
          align-items: center;

          &__icon {
            display: flex;
            align-items: center;
            margin-bottom: 21px;
          }
        }

        .image-text {
          max-width: 300px;
          margin-bottom: 40px;
          float: left;
          vertical-align: top;
          text-align: left;

          &:nth-child(2n) {
            float: right;
          }

          &:nth-child(2n - 1) {
            margin-right: 49px;
          }

          .sub-info {
            line-height: 1.8;
            font-size: 15px;
            color: #343434;

            br {
              content: "";
              display: block;
              height: 30px;
            }
          }

          &__inner {
            max-width: 649px;
            margin: 60px auto 0 auto;
          }
        }

        .m-img {
          display: none;
        }
      }

      // top contents
      &--top {
        max-width: 720px;
        margin: 74px auto 55px auto;
        display: flex;
        justify-content: center;

        .title-contents {
          height: 230px;

          &__icon {
            display: flex;
            height: 73px;
            margin-bottom: 21px;
          }

          .sub-info {
            max-width: 169px;
          }
        }

        .top-img01 {
          background: url(../assets/images/skininfo/info/top-icon01.png) center /
            cover no-repeat;
          background-size: contain;
          width: 100px;
          height: 73px;
        }

        .top-img02 {
          background: url(../assets/images/skininfo/info/top-icon02.png) center /
            cover no-repeat;
          background-size: contain;
          width: 78px;
          height: 66px;
        }

        .top-img03 {
          background: url(../assets/images/skininfo/info/top-icon03.png) center /
            cover no-repeat;
          background-size: contain;
          width: 68px;
          height: 73px;
        }

        .top-img04 {
          background: url(../assets/images/skininfo/info/top-icon04.png) center /
            cover no-repeat;
          background-size: contain;
          width: 77px;
          height: 73px;
        }

        .top-img05 {
          background: url(../assets/images/skininfo/info/top-icon05.png) center /
            cover no-repeat;
          background-size: contain;
          width: 72px;
          height: 67px;
        }

        .top-img06 {
          background: url(../assets/images/skininfo/info/top-icon06.png) center /
            cover no-repeat;
          background-size: contain;
          width: 73px;
          height: 73px;
        }

        .top-img07 {
          background: url(../assets/images/skininfo/info/top-icon07.png) center /
            cover no-repeat;
          background-size: contain;
          width: 64px;
          height: 74px;
        }

        .top-img08 {
          background: url(../assets/images/skininfo/info/top-icon08.png) center /
            cover no-repeat;
          background-size: contain;
          width: 69px;
          height: 77px;
        }

        .top-img09 {
          background: url(../assets/images/skininfo/info/top-icon09.png) center /
            cover no-repeat;
          background-size: contain;
          width: 67px;
          height: 73px;
        }
      }

      // process
      &--process {
        margin-bottom: 60px;

        h3 {
          font-size: 23px;
          font-weight: 500;
          letter-spacing: 0.2px;
          color: #000;
        }

        .process-contents {
          height: 84px;
          margin-top: 32px;
          background-image: url(../assets/images/skininfo/bg-process.png);
          background-size: cover;

          &__inner {
            position: relative;
          }

          &__text {
            display: inline-block;
            padding: 20px 0 17px 0;
            position: absolute;

            &:first-child {
              left: 28%;
            }

            &:nth-child(2) {
              left: 49%;
            }

            &:nth-child(3) {
              left: 70.5%;
            }

            text-align: left;

            .info {
              margin-bottom: 3px;
              font-size: 19px;
            }
          }
        }
      }

      // cause
      &--cause {
        padding: 50px 0 56px 0;
        background: url(../assets/images/skininfo/bg-cause.png) center / cover
          no-repeat;

        .title {
          font-size: 25px;
          line-height: 1.32;
        }

        .info {
          margin-bottom: 5px;
        }

        .cause-inner {
          display: flex;
          justify-content: center;
          margin-top: 51px;
        }

        .cause-contents__icon {
          height: 98px;
        }

        .icon {
          width: 105px;
          height: 105px;
        }

        .cause-img01 {
          background: url(../assets/images/skininfo/info/cause-icon01.png)
            center / cover no-repeat;
          background-size: contain;
        }

        .cause-img02 {
          background: url(../assets/images/skininfo/info/cause-icon02.png)
            center / cover no-repeat;
          background-size: contain;
        }

        .cause-img03 {
          background: url(../assets/images/skininfo/info/cause-icon03.png)
            center / cover no-repeat;
          background-size: contain;
        }

        .cause-img04 {
          background: url(../assets/images/skininfo/info/cause-icon04.png)
            center / cover no-repeat;
          background-size: contain;
        }

        .cause-img05 {
          background: url(../assets/images/skininfo/info/cause-icon05.png)
            center / cover no-repeat;
          background-size: contain;
        }

        .cause-img06 {
          background: url(../assets/images/skininfo/info/cause-icon06.png)
            center / cover no-repeat;
          background-size: contain;
        }

        .cause-img07 {
          background: url(../assets/images/skininfo/info/cause-icon07.png)
            center / cover no-repeat;
          background-size: contain;
        }
      }

      // symptom
      &--symptom {
        padding: 50px 0 70px 0;

        &::after {
          content: "";
          display: block;
          clear: both;
        }

        .title {
          font-size: 25px;
          line-height: 1.32;
        }

        .info {
          font-size: 21px;
          margin-bottom: 7px;
          line-height: 1;
        }

        .image-text {
          .sub-info {
            font-size: 16px;
          }
        }

        .symptom {
          &-bg {
            margin: 47px auto 0 auto;
            width: 442px;
            height: 366px;
            background: url(../assets/images/skininfo/info/symptom-img.png)
              center / cover no-repeat;
            background-size: contain;
          }
        }
      }

      // cure
      &--cure {
        padding: 50px 0 70px 0;
        background: url(../assets/images/skininfo/bg-cure.png) center / cover
          no-repeat;

        .cure-bg {
          margin: 30px auto 30px auto;
          width: 433px;
          height: 292px;
          background: url(../assets/images/skininfo/info/cure-img.png) center /
            cover no-repeat;
          background-size: contain;
        }

        &::after {
          content: "";
          display: block;
          clear: both;
        }

        .title {
          font-size: 25px;
          line-height: 1.32;
        }

        .info {
          font-size: 21px;
          margin-bottom: 7px;
          line-height: 1;
        }

        .image-text {
          .sub-info {
            font-size: 16px;
            line-height: 1.69;
            letter-spacing: -0.32px;
          }
        }
      }

      // rule
      &--rule {
        margin: 60px 0 100px 0;

        .title {
          font-size: 24px;
        }

        .rule {
          &-bg {
            margin: 50px auto 20px auto;
            width: 501px;
            height: 187px;
            background: url(../assets/images/skininfo/info/rule-img.png) center /
              cover no-repeat;
            background-size: contain;
          }

          &-text {
            margin-bottom: 40px;
            text-align: left;

            .info {
              font-size: 21px;
              margin-bottom: 7px;
            }

            .sub-info {
              font-size: 16px;
              line-height: 1.69;
              letter-spacing: -0.32px;
              color: #343434;
            }
          }
        }
      }

      // resource
      &--resource {
        padding: 50px 0 95px 0;
        background: url(../assets/images/skininfo/bg-cure.png) center / cover
          no-repeat;

        .source {
          padding-top: 31px;
          display: flex;
          justify-content: flex-end;
          font-size: 14px;
          font-weight: 500;
          color: #b7b7b7;
        }

        .resource-tab {
          .react-tabs__tab {
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(100% / 2);
            height: 40px;
            margin-right: 5px;
            background: #b4ece4;
            border-radius: 8px;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            cursor: pointer;

            &:last-child {
              margin: 0;
            }

            &-list {
              display: flex;
              justify-content: center;
              margin: 40px 0;
            }
          }

          .react-tabs__tab--selected {
            background: #4ad8c0;
          }
        }

        // resource slider
        .resource-slider {
          position: relative;
          img {
            width: 325px;
          }

          .slider-arrow {
            position: absolute;
            bottom: -41px;
            display: flex;
            align-items: center;
          }

          .slider-arrow.prev {
            left: 40%;
          }

          .slider-arrow.next {
            right: 40%;
          }

          .slick-prev,
          .slick-next {
            &::before {
              width: 25px;
              height: 25px;
            }
          }

          .slick-prev {
            left: 0;
          }

          .slick-next {
            right: 40%;
          }

          .slick-dots {
            bottom: -45px;

            li {
              display: none;
              width: 100%;
              margin: 0;
              cursor: unset;
            }

            .slick-active {
              display: block;
              p {
                font-size: 14px;
                line-height: 2.36;
                color: #b7b7b7;
              }

              span {
                color: #000;
              }
            }
          }
        }

        .urticaria .resource-slider {
          img {
            width: 100%;
          }
        }
      }
    }

    // detail
    &__detail {
      &--inner {
        margin-top: 0;
        padding-bottom: 70px;
      }

      &--title {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
          font-size: 19px;
          font-weight: bold;
          letter-spacing: 0.28px;
          line-height: 1.61;
          color: #4cb9a9;
        }
      }

      &--icon {
        display: flex;
        align-items: center;

        .icon {
          margin-right: 15px;
        }
      }

      &--info {
        margin-top: 20px;

        span {
          font-size: 16px;
          line-height: 1.94;
          font-weight: 500;
        }

        strong {
          font-weight: bold;
        }

        .btn-area {
          margin-top: 5px;

          .btn {
            font-size: 14px;
            line-height: 2.36;
            font-weight: bold;
            text-decoration: underline;
            color: #b7b7b7;
          }
        }

        .hidden-text {
          display: inline;
        }
      }
    }

    .icon-virus {
      width: 29px;
      height: 29px;
      background: url("../assets/icons/icon-virus.png") center / cover no-repeat;
    }

    .icon-plaster {
      width: 25px;
      height: 25px;
      background: url("../assets/icons/icon-plaster.png") center / cover
        no-repeat;
    }

    .icon-doctor {
      width: 26px;
      height: 27px;
      background: url("../assets/icons/icon-doctor.png") center / cover
        no-repeat;
    }

    .icon-pills {
      width: 24px;
      height: 24px;
      background: url("../assets/icons/icon-pills.png") center / cover no-repeat;
    }
  }
}

// 1680px
@include mq(1680px) {
  .skin-container {
    margin-top: 50px;
  }

  .skin-contents {
    max-width: 580px;
  }
}

// 1280px
@include mq(desktop) {
  .skin-container {
    margin-top: 60px;
  }

  .skin-contents {
    max-width: 1024px;
    padding: 80px 0 73px 0;
  }

  .skin-title {
    font-size: 30px;
  }

  .skin-contents__image {
    height: auto;
  }

  // info
  .skin-contents__text {
    display: none;
  }

  .skin-contents__title {
    font-size: 23px;
    color: #305674;
  }

  .skin-contents__title--wrap {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 30px 22px 30px;
  }

  .skin-contents__title--img {
    margin-top: 20px;
  }

  .skin-contents__info h4 {
    margin: 0 30px 40px 30px;
    line-height: 1.94;
    font-size: 16px;
    color: #305674;
  }

  // detail
  .skin-contents__detail {
    padding-top: 0;
    border-top: 8px solid #f5f5f5;
  }

  .skin-contents__detail--inner {
    padding: 30px 0;
    border-bottom: 8px solid #f5f5f5;

    &:last-child {
      border-bottom: 0;
    }
  }

  .skin-contents__detail--icon {
    width: 50px;
  }

  .skin-contents__detail--title {
    margin: 0 30px;
  }

  .skin-contents__detail--title p {
    font-size: 19px;
    font-weight: bold;
  }

  .skin-contents__detail--info {
    margin: 20px 30px 0 30px;
  }

  .skin-contents__detail--info span {
    margin-left: 0;
    font-size: 16px;
    line-height: 1.94;
  }

  .skin-contents__detail--icon {
    width: auto;
  }

  // image
  .skin-contents__image--info {
    padding: 97px 0 250px 0;
  }

  .skin-contents__image--info .image-text {
    &:nth-child(2n - 1) {
      margin-right: 0;
    }

    &:nth-child(2n) {
      float: left;
    }

    max-width: none;
  }

  // resource
  .skin-contents__image--resource .resource-slider img {
    width: 100%;
  }

  .skin-contents__image--resource .source {
    padding-top: 20px;
  }

  .skin-contents__image--resource .resource-tab .react-tabs__tab-list {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin: 30px 0;
  }

  .skin-contents__image--resource .resource-tab .react-tabs__tab {
    width: calc(100% / 2 - 2px);
    margin-right: 4px;
    font-size: 14px;
  }

  .skin-contents__image--resource .resource-tab .react-tabs__tab:nth-child(2) {
    margin: 0;
  }

  .skin-contents__image--resource .resource-tab .react-tabs__tab:nth-child(3) {
    margin-top: 4px;
  }
}

@include mq(tablet) {
  // image
  .skin-contents__image--process .process-contents__text {
    &:first-child {
      left: 21%;
    }

    &:nth-child(3) {
      left: 77.5%;
    }
  }

  // process
  .skin-contents__image--process .process-contents {
    height: 63px;
    background-image: url(../assets/images/skininfo/t-bg-process.png);
  }

  .skin-contents__image--process .process-contents__text {
    padding: 8px 0;

    &:first-child {
      left: 28.5%;
    }

    &:nth-child(3) {
      left: 70.5%;
    }
  }

  .skin-contents__image--process .process-contents__text .info {
    margin-bottom: 5px;
  }
}

@include mq(mobile) {
  // image
  .skin-contents__image--info .image-contents {
    width: calc(100% / 2);
    float: left;
  }

  .skin-contents__image--info .title {
    font-size: 25px;
  }

  .skin-contents__image--info .img {
    display: none;
  }

  .skin-contents__image--info .m-img {
    display: inline-block;
    max-width: 100%;
  }

  .skin-contents__image--info .info {
    margin-bottom: 9px;
    font-size: 21px;
  }

  .skin-contents__image--info .image-text__inner {
    padding: 0 30px;
  }

  // top
  .skin-contents__image--top {
    width: 100%;
    padding: 0 28px;
    display: inline-block;
    margin: 52px auto 30px auto;
  }

  .skin-contents__image--top .top-img01 {
    width: 83px;
    height: 42px;
  }

  .skin-contents__image--top .top-img02 {
    width: 67px;
    height: 56px;
  }

  .skin-contents__image--top .top-img03 {
    width: 60px;
    height: 64px;
  }

  .skin-contents__image--top .top-img04 {
    width: 68px;
    height: 64px;
  }

  .skin-contents__image--top .top-img05 {
    width: 65px;
    height: 60px;
  }

  .skin-contents__image--top .top-img06 {
    width: 63px;
    height: 64px;
  }

  .skin-contents__image--top .top-img07 {
    width: 58px;
    height: 68px;
  }

  .skin-contents__image--top .top-img08 {
    width: 64px;
    height: 72px;
  }

  .skin-contents__image--top .top-img09 {
    width: 64px;
    height: 70px;
  }

  .skin-contents__image--top .title-contents {
    height: 180px;
    margin-bottom: 30px;
  }

  .skin-contents__image--top .title-contents__icon {
    height: 64px;
    display: flex;
    align-items: center;
    margin-bottom: 17px;
  }

  // process
  .skin-contents__image--process {
    margin-bottom: 30px;
  }

  .skin-contents__image--process h3 {
    font-size: 20px;
  }

  .skin-contents__image--process .process-contents {
    height: 114px;
    background-image: url(../assets/images/skininfo/m-bg-process.png);
  }

  .skin-contents__image--process .process-contents__text {
    padding: 17px 0;

    &:first-child {
      left: 8%;
    }

    &:nth-child(2) {
      left: 38%;
    }

    &:nth-child(3) {
      left: 69%;
    }
  }

  .process-contents__text .sub-info {
    max-width: 91px;
  }

  // cause
  .skin-contents__image--cause .title {
    font-size: 25px;
  }

  .skin-contents__image--cause .info {
    margin-bottom: 5px;
  }

  .skin-contents__image--cause {
    padding: 30px 0 20px 0;
    background: url(../assets/images/skininfo/m-bg-cause.png) center / cover
      no-repeat;
  }

  .skin-contents__image--cause .cause-inner {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: inherit;
    padding: 0 28px;
    margin-top: 35px;
  }

  .skin-contents__image--cause .icon {
    width: 93px;
    height: 93px;
  }

  .skin-contents__image--cause .cause-contents__icon {
    height: 85px;
  }

  .skin-contents__image--cause .cause-contents {
    margin-bottom: 40px;
  }

  // symptom
  .skin-contents__image--symptom {
    padding: 30px 0 10px 0;
  }

  .skin-contents__image--symptom .title {
    font-size: 25px;
  }

  .skin-contents__image--symptom .symptom-bg {
    width: 321px;
    height: 264px;
  }

  // cure
  .skin-contents__image--cure {
    padding: 30px 0 10px 0;
    background: url(../assets/images/skininfo/m-bg-cure.png) center / cover
      no-repeat;
  }

  .skin-contents__image--cure .title {
    font-size: 25px;
  }

  .skin-contents__image--cure .cure-bg {
    margin: 30px auto 20px auto;
    width: 321px;
    height: 264px;
  }

  .skin-contents__image--cure .image-text__inner {
    margin: 0 auto;
  }

  // rule
  .skin-contents__image--rule {
    margin: 30px 0 0 0;
  }

  .skin-contents__image--rule .title {
    font-size: 25px;
  }

  .skin-contents__image--rule .rule-bg {
    margin: 25px auto 10px auto;
    width: 354px;
    height: 145px;
  }

  .skin-contents__image--rule .image-text__inner {
    margin: 0 auto;
  }

  // resource
  .skin-contents__image--resource .image-text__inner {
    margin: 30px auto 0 auto;
  }

  .skin-contents__image--resource .resource-slider .slider-arrow {
    bottom: -64px;
  }

  .skin-contents__image--resource .resource-slider .slick-dots {
    bottom: -68px;
  }

  .skin-contents__image--resource .resource-slider .slider-arrow.prev {
    left: 29%;
  }

  .skin-contents__image--resource .resource-slider .slider-arrow.next {
    right: 29%;
  }
}

@include mq(mini) {
  .skin-contents__image--info .info {
    font-size: 16px;
  }

  .skin-contents__image--info .sub-info {
    font-size: 11px;
  }

  .skin-contents__image--process .process-contents__text {
    &:first-child {
      left: 9%;
    }

    &:nth-child(2) {
      left: 44%;
    }

    &:nth-child(3) {
      left: 79%;
    }
  }

  .skin-contents__image--process .process-contents__text .info {
    font-size: 14px;
  }
}
