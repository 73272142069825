@import 'src/styles/modules/mixins';

#map {
  width: 100%;
  height: 596px;
}

// media query
@include mq(mobile) {
  #map {
    height: 375px;
  }
}
