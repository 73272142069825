/*
 * File        : hospital.scss
 * Description : HospitalPage 관련 css
*/

.hospital {
  &-header {
    background: url("../assets/images/company01.jpg") 50% / cover no-repeat;

    .section-title {
      text-align: center;
      h2 {
        max-width: 100%;
      }
    }
  }

  &-container {
    .contents-inner {
      text-align: center;
    }
  }

  &-title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
  }

  &-table {
    .ant-table-pagination {
      justify-content: center;
    }

    .ant-table-pagination > * {
      justify-content: center;
      align-items: center;
      display: flex;
    }

    .ant-pagination-item a {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// media query
@include mq(desktop) {
  .hospital-title {
    font-size: 30px;
  }
}
