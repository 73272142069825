#content {
  min-height: 100%;
  overflow-x: hidden;
}

// section
.section {
  &-header {
    position: relative;
    height: 249px;
    width: 100%;
    margin-top: 50px;

    h2 {
      max-width: 483px;
      padding: 80px 0;
      color: #fff;
      font-size: 34px;
      font-weight: 500;
      line-height: 1.42;
      letter-spacing: 0.31px;
    }
  }

  &-title {
    width: 780px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &-dimmed {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #305674, #305674);
    opacity: 0.49;
    mix-blend-mode: multiply;
    background-blend-mode: multiply;
  }
}

.contents-inner {
  max-width: 1024px;
  padding: 145px 0 151px 0;
  margin: 0 auto;
}

// top button
.top-button {
  position: fixed;
  bottom: 30px;
  right: 30px;

  .icon-top {
    opacity: 0;
    transition: opacity 0.3s;
  }

  .icon-top.active {
    background: url(../../assets/icons/icon-top.png) center / cover no-repeat;
    width: 50px;
    height: 50px;
    transition: opacity 0.3s;
    opacity: 1;
    box-shadow: 0 10px 10px 0 rgba(11, 137, 119, 0.18);
    border-radius: 50%;
  }
}

// slick
.slick-prev,
.slick-next {
  width: 36px;
  height: 36px;
}
.slick-prev::before,
.slick-next::before {
  content: "";
  width: 36px;
  height: 36px;
  display: inline-block;
  opacity: 1;
}
.slick-prev::before {
  background: url("../../assets/icons/icon-arrow-prev.png") center / cover
    no-repeat;
}
.slick-next::before {
  background: url("../../assets/icons/icon-arrow-next.png") center / cover
    no-repeat;
}
.slick-dots li.slick-active button:before {
  color: #4cb9a9;
}

// media query
@media screen and (min-width: 768px) and (max-width: 1680px) {
  .section-title {
    width: 580px;
  }
}

@include mq(desktop) {
  .contents-inner {
    margin: 0 30px;
    padding: 60px 0;
  }

  .section-header {
    margin-top: 60px;
  }

  .top-button {
    right: 15px;
    bottom: 20px;
  }

  .top-button .icon-top.active {
    width: 50px;
    height: 50px;
  }
}

@include mq(mini) {
  .contents-inner {
    margin: 0 20px;
  }
}
