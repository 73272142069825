@import "src/styles/modules/mixins", "src/styles/modules/variables";

// header
.header {
  position: fixed;
  height: 50px;
  width: 100%;
  background: transparent;
  transition: transform 0.3s ease, opacity 0.3s;
  z-index: 1000;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    width: 100%;
    z-index: 1;
    background: linear-gradient(to right, #305674, #39aaab);
    transition: 0.3s;
    opacity: 0;
  }

  &__container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &__align {
    position: relative;
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    height: 100%;
    justify-content: space-between;
    z-index: 100;
  }

  &-logo {
    position: absolute;
    left: 0;
    top: 8px;
  }

  &-logo__link {
    background: url("../../../assets/common/logo.png") center / cover no-repeat;
    display: inline-block;
    width: 121px;
    height: 35px;
    text-indent: -9999px;
  }

  &-login__area {
    justify-content: flex-end;
    margin-top: 50px;
  }

  &-login {
    margin-right: 20px;
  }

  &-login__text {
    font-size: 16px;
    color: #fff;
  }
}

// nav
.nav {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  &-depth1 {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  &-depth1__link {
    display: flex;
    margin-top: 14px;
    color: #fff;
  }

  &-depth1__item {
    position: relative;
  }

  &-depth1__title {
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.21px;

    &:hover {
      color: #4cb9a9;
      transition: 0.2s ease;
    }
  }

  &-depth2 {
    display: none;
  }

  &-depth2__link {
    font-size: 16px;
  }
}

// media query
@media screen and (min-width: 1281px) {
  .header {
    &.header-active {
      &::after {
        width: 100%;
      }
    }

    &-active,
    &-scroll,
    &:hover,
    &:focus {
      .header-logo__link {
        transition: all 0.3s ease;
      }
      .nav-depth1__link {
        transition: all 0.3s ease;
      }
      .header-login__icon {
        transition: all 0.3s ease;
      }
      .header-login__text {
        transition: all 0.3s ease;
      }

      .header-login__text {
        color: #343434;
      }
    }

    &-color {
      background: linear-gradient(100deg, #305674, #39aaab);
    }

    &-scroll {
      &::after {
        opacity: 1;
      }
    }

    &-btn__trigger {
      display: none;
    }
  }
}

//1280px
@include mq(desktop) {
  .header {
    height: 60px;

    &::after {
      background: #305674;
    }
  }

  .header-color {
    background: #305674;
  }

  .header__align {
    align-items: center;
  }

  .header-logo__link {
    background: url("../../../assets/common/logo-mobile.png") center / cover
      no-repeat;
    height: 30px;
    width: 104px;
  }

  .header-scroll {
    &::after {
      opacity: 1;
      height: 60px;
      background: #305674;
    }
  }

  .header-logo {
    top: 17px;
    left: 30px;
    width: 104px;
    height: 30px;
    z-index: 1000;
  }

  .header-mobile {
    overflow: visible;

    .nav {
      // display: block;
      left: 0;
    }

    .header-btn__burger--top {
      transform: matrix(0.7071, 0.7071, -0.7071, 0.7071, -10, 0);
    }
    .header-btn__burger--middle {
      opacity: 0;
    }
    .header-btn__burger--bottom {
      transform: matrix(0.7071, -0.7071, 0.7071, 0.7071, -10, 0);
      width: 20px;
    }
  }

  .header-btn__trigger {
    position: absolute;
    right: 30px;
    width: 20px;
    height: 13px;
    z-index: 1000;
  }

  .header-btn__burger {
    background-color: #fff;
    display: block;
    position: absolute;
    height: 2px;
    left: 50%;
    top: 50%;
    text-indent: -9999px;
    width: 20px;
  }

  .header-btn__burger--top {
    transform: matrix(1, 0, 0, 1, -10, -7);
    transition: all ease 0.3s;
  }

  .header-btn__burger--middle {
    transform: matrix(1, 0, 0, 1, -10, 0);
    transition: all ease 0.3s;
  }

  .header-btn__burger--bottom {
    transform: matrix(1, 0, 0, 1, -10, 7);
    transition: all ease 0.3s;
  }

  .nav {
    background-color: #305674;
    bottom: 0;
    height: 100vh;
    left: 100%;
    top: 0;
    transition: left 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    position: fixed;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: initial;
    padding-top: 465px;
    width: 100%;
    z-index: 100;
  }

  .nav-depth1 {
    display: block;
    margin: 0 0 15px 0;
  }

  .nav-depth1__link {
    margin-top: 0;
    align-items: center;
    justify-content: space-between;
    color: #fff;

    &::after {
      content: "";
      width: 12px;
      height: 11px;
      background: url("../../../assets/icons/icon-go.png") center / cover
        no-repeat;
    }
  }

  .nav-depth1__item {
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 0.5px;
      background: #fff;
      margin-top: 4px;
    }
  }

  .nav-depth1__title {
    font-size: 19px;
  }

  .nav-depth2 {
    display: inline-block;
    text-align: left;
    padding: 36px 0 24px 0;
    border-bottom: 1px solid #ededed;
  }

  .nav-depth2__link {
    font-size: 14px;
  }

  .nav-depth2__item {
    display: inline-block;
    margin-right: 7px;
    margin-bottom: 12px;

    a {
      padding: 8px 21px 10px 18px;
      height: 38px;
      display: inline-block;
      border: solid 1px #d3d3d3;
      font-size: 14px;
      color: #707070;
      font-weight: bold;
    }
  }
}
