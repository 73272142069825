/*
 * File        : medicalInfo.scss
 * Description : MedicalInfoPage 관련 css
*/

.medical {
  &-header {
    background: url("../assets/images/medical-bg.png") center / cover no-repeat;
  }

  &-container {
    margin: 50px 0 200px 0;
  }

  &-contents {
    &__inner {
      max-width: 780px;
      margin: 0 auto;
    }

    &__item {
      margin-bottom: 80px;
      cursor: pointer;

      img {
        width: 100%;
      }

      span {
        color: #b7b7b7;
      }

      &--text {
        margin-top: 21px;

        h2 {
          margin: 0 0 8px 0;
          font-size: 31px;
          font-weight: bold;
          line-height: 1.06;
        }

        h3 {
          margin: 15px 0 19px 0;
          font-size: 17px;
          font-weight: 500;
          line-height: 1.82;
          letter-spacing: -0.26px;
        }

        span {
          font-size: 14px;
          font-weight: bold;
          color: #b7b7b7;
        }

        .date {
          margin-right: 20px;
        }
      }

      &--bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a,
        button {
          display: flex;
          align-items: center;
          font-size: 17px;
          font-weight: bold;
          line-height: 1.94;
          color: #4cb9a9;
        }

        .icon-more {
          background: url(../assets/icons/icon-more.png) center / cover
            no-repeat;
          width: 17px;
          height: 17px;
          margin-right: 9px;
        }

        span {
          font-size: 14px;
          line-height: 2.36;
          font-weight: 500;
        }
      }
    }

    &__more {
      max-width: 1024px;
      margin: 0 auto 100px auto;

      p {
        display: flex;
        justify-content: space-between;
      }

      a,
      span {
        display: flex;
        align-items: center;
        font-size: 17px;
        font-weight: bold;
        color: #305674;
        letter-spacing: 0.17px;
      }

      .prev::before,
      .next::after {
        content: "";
        display: inline-block;
        width: 25px;
        height: 25px;
      }

      .prev {
        &::before {
          background: url("../assets/icons/icon-arrow-prev02.png") center /
            cover no-repeat;
          margin-right: 8px;
        }
      }
      .next {
        &::after {
          background: url("../assets/icons/icon-arrow-next02.png") center /
            cover no-repeat;
          margin-left: 8px;
        }
      }

      .list {
        padding: 4px 21px 5px 22px;
        border: 1px solid #305674;
        border-radius: 20px;

        .icon-list {
          width: 16px;
          height: 14px;
          background: url(../assets/icons/icon-list.png) center / cover
            no-repeat;
          margin-right: 8px;
        }
      }
    }
  }

  &-title {
    max-width: 580px;
    margin: 0 auto;
  }

  &-info {
    margin-top: 130px;

    &__contents {
      margin-bottom: 117px;
    }

    &__video {
      margin: 50px 0;
      img {
        width: 100%;
      }

      &--item {
        width: 100%;
        height: 100%;
      }
    }

    &__detail {
      p {
        font-size: 17px;
        font-weight: 500;
        line-height: 1.94;
        letter-spacing: -0.26px;
      }

      span {
        display: flex;
        justify-content: flex-end;
        margin: 8px 0 0 0;
        font-size: 14px;
        font-weight: 500;
        color: #b7b7b7;
      }

      .move-skininfo {
        display: inline-block;
        margin: 42px 0;
        text-decoration: underline;
        font-size: 20px;
        font-weight: bold;
        color: #305674;
      }
    }
  }
}

// media query
@media screen and (min-width: 1240px) and (max-width: 1680px) {
  .medical-contents__inner {
    max-width: 580px;
  }
}

// 1280px
@include mq(desktop) {
  // medical header
  .medical-header {
    height: 299px;
    background: url(../assets/images/medical-bg-mobile.png) center / cover
      no-repeat;

    .section-title {
      h2 {
        max-width: 441px;
        font-size: 34px;
        line-height: 1.42;
        letter-spacing: 0.31px;
      }
    }
  }

  // medical contents
  .medical-container {
    margin: 20px 0 73px 0;
  }

  .medical-contents__inner {
    max-width: 580px;
  }

  .medical-contents__item--text {
    h2 {
      margin: 0 0 11px 0;
      font-size: 28px;
    }

    h3 {
      margin: 11px 0 22px 0;
      font-size: 17px;
      line-height: 1.94;
    }
  }

  // medical info
  .medical-info {
    margin-top: 100px;
  }

  .medical-info__contents {
    margin-bottom: 50px;
  }

  .medical-info__video {
    margin: 30px 0;
  }

  .medical-info__detail {
    margin: 0 30px;
  }

  .medical-info__detail span {
    margin: 50px 0;
  }

  .medical-contents__more {
    margin: 0 auto 70px auto;
    max-width: 780px;
    .list {
      margin-top: 120px;
    }
  }

  .medical-contents__item--image {
    position: relative;
    height: 326px;
    overflow: hidden;

    img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .medical-contents__item--bottom {
    button {
      font-size: 16px;
    }
  }
}

@include mq(tablet) {
  .medical-contents__more p {
    margin: 0 30px;
  }
}

@include mq(mobile) {
  .medical-header {
    .section-title {
      width: 100%;
      margin: 0 30px;

      h2 {
        max-width: 239px;
      }
    }
  }

  .medical-contents__item--image {
    position: relative;
    height: 250px;

    img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      height: 100%;
      width: auto;
    }
  }

  .medical-contents__item--text {
    margin: 22px 30px 0 30px;
  }

  .medical-contents__item--bottom {
    margin: 0 30px;
  }
}

@include mq(phone) {
  .medical-contents__more {
    a,
    span {
      font-size: 14px;
    }
  }
}

@include mq(xsmallMobile) {
  .medical-contents__more {
    p {
      margin: 0 20px;
    }

    a,
    span {
      font-size: 12px;
    }
  }
}
