/*
 * File        : contact.scss
 * Description : ContactPage 관련 css
*/

.contact {
  &-header {
    background: url("../assets/images/contact01.jpg") 50% / cover no-repeat;
  }

  &-title {
    font-size: 25px;
    font-weight: 800;

    &::after {
      content: "";
      display: inline-block;
      width: 100%;
      height: 1px;
      border-bottom: 1px solid #ddd;
    }
  }

  &-info {
    &__address {
      margin: 40px 0 10px 0;
      font-size: 15px;
    }

    &__number {
      font-size: 15px;
    }
  }
}
