/*
 * File        : skinInfoContainer.scss
 * Description : SkinInfoPage Tab, Slider 관련 css
*/

// tab
.skin-tab {
  &__wrap {
    width: 100%;
    background-color: #f7fbfd;
    z-index: 100;
  }

  &__mobile {
    display: none;

    &--wrap {
      display: none;
    }
  }

  &__contents {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-bottom: 0.5px solid #d3d3d3;
  }

  &__button {
    position: relative;
    height: 100%;
    display: block;
    margin-right: 0;
    font-size: 15px;
    font-weight: 500;
    color: #305674;

    &--wrap {
      &:last-child {
        .skin-tab__button {
          &::after {
            display: none;
          }
        }
      }
    }

    &::after {
      content: "|";
      height: 16px;
      width: 1px;
      margin: 0 12px;
      color: #b9c8d3;
    }

    &--active {
      color: #4cb9a9;
    }
  }
}

// slider
.skin-contents__slider {
  margin-top: 48px;

  &--area {
    background-color: #305674;
  }

  &--inner {
    max-width: 918px;
    padding: 80px 0 0 0;
    margin: 0 auto;
    text-align: center;
  }

  &--title {
    h3 {
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0.28px;
      color: #fff;
    }
  }

  &--list {
    position: relative;
    width: auto !important;
    transition: 0.3s;
    margin-right: 12px;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.08);
    }

    &:hover {
      box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.08);
      transition: 0.3s;

      .skin-contents__slider--hover {
        opacity: 1;
        transition: 0.2s;
      }
    }
  }

  &--hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #214058;
    border-radius: 4px;
    font-size: 20px;
    color: #fff;
  }

  &--item {
    height: 165px;
    padding: 30px 63px;
    background-color: #fff;
    border-radius: 5px;
    text-align: center;

    .title-active {
      color: #4cb9a9;
    }
    .info-active {
      color: #343434;
    }

    p {
      font-size: 21px;
      margin-bottom: 16px;
      font-weight: bold;
      color: #305674;
    }

    span {
      display: block;
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: -0.28px;
    }
  }

  &--hover {
    opacity: 0;
    font-size: 21px;
  }

  &--mobile {
    display: none;
  }

  // slick
  .slick-list {
    margin-right: -20px;
    height: 300px;
  }

  .slick-prev,
  .slick-next {
    transition: box-shadow 0.3s;
    border-radius: 50%;
    top: 27%;
    transform: translate(0, -27%);

    &:hover {
      box-shadow: 0 6px 10px 0 #234d6f;
      transition: box-shadow 0.3s;
    }
  }

  .slick-prev {
    left: -60px;

    &::before {
      background: url(../assets/icons/icon-slider-arrow-prev.png) center / cover
        no-repeat;
    }
  }

  .slick-next {
    right: -60px;

    &::before {
      background: url(../assets/icons/icon-slider-arrow-next.png) center / cover
        no-repeat;
    }
  }

  .slick-dots {
    bottom: 80px;
  }
}

// 1280px
@include mq(desktop) {
  // tab
  .skin-container.active {
    .skin-tab__wrap {
      background: #fff;

      .icon-expand {
        background: url(../assets/icons/icon-arrow-up.png) center / cover
          no-repeat;
      }

      span {
        color: #305674;
        font-weight: 500;
      }
    }

    .skin-tab__mobile--wrap {
      &::before {
        content: "";
        position: absolute;
        top: 41px;
        left: 0;
        width: 100%;
        box-shadow: 0px 1.5px 35px 4.3px #3056745e;
        z-index: 10;
      }
    }
  }

  .skin-tab__wrap {
    position: fixed;
    background: #305674;
    border-top: 0.5px solid #fff;
    box-shadow: 0 5px 15px 0 rgba(48, 86, 116, 0.08);
  }

  .skin-tab__inner {
    padding: 0 30px;
  }

  .skin-tab__mobile {
    display: block;

    &--btn {
      width: 100%;
      height: 41px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    span {
      font-size: 17px;
      color: #fff;
      font-weight: normal;
    }

    .icon-expand {
      width: 13px;
      height: 8px;
      background: url(../assets/icons/icon-arrow-down02.png) center / cover
        no-repeat;
    }
  }

  .skin-tab {
    &__contents {
      &--mobile {
        width: 100%;
        position: fixed;
        height: 0;
        left: 0;
        background: #f7fbfd;
        transition: height 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      }
    }

    &__mobile {
      &--wrap {
        display: block;
      }
    }

    &__button {
      &--wrap {
        width: calc(100% / 2);
        display: inline-block;
      }
    }
  }

  .skin-tab__contents--mobile.active {
    height: 100%;
    padding: 10px 30px 0 30px;

    .skin-tab__button--wrap {
      display: inline-block;
      border-bottom: 1px solid rgba(185, 200, 211, 0.3);
    }
  }

  .skin-tab__button--wrap {
    display: none;
  }

  .skin-tab__contents {
    display: none;
  }

  .skin-tab__button {
    display: inline-block;
    margin-right: 0;
    line-height: 43px;
    height: 43px;
    font-size: 16px;
    font-weight: 500;
    color: #305674;
    &::after {
      display: none;
    }
  }

  .skin-tab__button--active {
    color: #4cb9a9;
    font-weight: normal;
  }

  // slider
  .skin-contents__slider--area {
    display: none;
  }

  .skin-contents__slider {
    display: none;
  }

  .skin-contents__slider--inner {
    padding: 50px 0 59px 0;
    margin: 0 40px;
  }

  .skin-contents__slider--list {
    width: 100% !important;
    margin-right: 0;
  }

  .skin-contents__slider--title h3 {
    font-size: 20px;
  }

  .skin-contents__slider--item {
    padding: 35px 40px 56px 40px;
    height: auto;
  }

  .skin-contents__slider--item p {
    margin-bottom: 25px;
    font-size: 19px;
  }

  .skin-contents__slider--item span {
    font-size: 15px;
    line-height: 1.53;
    letter-spacing: 0.15px;
  }
}

@media (min-width: 1050px) and (max-width: 1280px) {
  .skin-tab__mobile {
    margin: 0 30px;
  }
}

@include mq(smallMobile) {
  .skin-tab__button {
    font-size: 17px;
  }
}
