$devices: (
  xsmallMobile: 320px,
  mini: 360px,
  phone: 375px,
  smallMobile: 414px,
  mobile: 767px,
  tablet: 768px,
  underDesktop: 1024px,
  desktop: 1280px,
  maxDesktop: 1980px,
);

@mixin mq($device) {
  @if map-has-key($devices, $device) {
    $device-width: map-get($devices, $device);
    @media screen and (max-width: #{$device-width}) {
      @content;
    }
  } @else {
    @media screen and (max-width: $device) {
      @content;
    }
  }
}
